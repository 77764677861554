.app-wrapper {
    margin: 0;
    min-height: 100vh;
    direction:rtl;
    color: #383838;
    background-color: #eee;
    font-size: 16px;

    .header-wrapper{
        margin: 0;
        position:fixed;
        padding: 20px;
        height:40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        background-color: #eee;

        .logo-wrapper{
            display:inline-block;
            padding: 8px;
            margin: 20px 5px 5px 15px;
            position: fixed;
            top:0;
            left:0;
            img {
                height: 30px;
                padding: 0;
                background: #0b71b8;
                border: solid 5px #0b71b8;
                border-top: 0;
                border-bottom: 1px;
            }
        }
        
    }  

    

    .content-wrapper{
        height: calc(100% - 40px);
        overflow: auto;
        padding: 60px 20px 20px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        
        .buttons-wrapper{
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }    
  
    }
}


