.circle-button{
    display: inline-block;
    justify-content: center;
    font-size: 30px;
    color:#fff;
    font-size:bold;
    
    svg{
        cursor:pointer;
    }
}