.order-item{
    position: relative;
    padding: 20px;
    margin: 40px 0 ;
    background: #fff;
    border: 1px solid #D6D6D6;
    border-radius: 12px;
    box-shadow: 5px 5px 20px #3F6E8331;
    
    

    .order-item-index{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -30px;
        right: 3px;
        border: solid 3px #FBBC2D;
        border-radius: 24px;
        font-size: 14px;
        font-weight: bold;
        color: #555555;
        width: 30px;
        height: 30px;
        background-color: #fff;
        box-shadow: 0px 9px 16px;
        overflow: hidden;
    }
    .order-item-info{
        display: block;
        margin: 3px; 
        border-bottom: solid 1px #eee;
        .order-item-label{
            display: inline-block;
            color: #0b71b8;
            border:0;
            padding: 5px;
            min-width: 110px;
            font-size: 14px;
            font-weight: bold;
        }
        .order-item-data{
            display: inline-block;
            padding: 6px 20px;
            background-color: #fff;
            font-size: 18px;
        }

    }

}